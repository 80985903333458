import { render, staticRenderFns } from "./contribution_event_approved.vue?vue&type=template&id=ab5d4d06"
import script from "./contribution_event_approved.vue?vue&type=script&lang=js"
export * from "./contribution_event_approved.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports